import { eventCenter } from '@evideo/frontend-utils'
/**
 * 我的课件上报字符串
 */
export interface MINE_COURSEWARES_EVENT_ATTR {
  courseware_id: string;
  courseware_type: string;
  file_path?: string;
}

/** 数据上报 模块id */
export enum STAT_MODULE_ID {
  PREPARE_LESSON_MODULE_ID = 'prepareLesson'
}

/** 数据上报 页面d */
export enum STAT_PAGE {
  MINE_COURSEWARES_PAGE_ID = 'mineCoursewares'
}

export enum STAT_EVENT {
  PREPARE_LESSON_MINECOURSEWARES_UPLOAD = 'prepareLesson_mineCoursewares_upload',
  PREPARE_LESSON_MINECOURSEWARES_DOWNLOAD = 'prepareLesson_mineCoursewares_download',
  PREPARE_LESSON_MINECOURSEWARES_CREATE = 'prepareLesson_mineCoursewares_create',
  PREPARE_LESSON_MINECOURSEWARES_EDIT = 'prepareLesson_mineCoursewares_edit',
  PREPARE_LESSON_MINECOURSEWARES_DELETE = 'prepareLesson_mineCoursewares_delete'
}

const statReport = (
  moduleid: string,
  pageid: string,
  eventid: string,
  eventattr: any
) => {
  eventCenter.send &&
    eventCenter.send('STAT_REPORT', {
      moduleid,
      pageid,
      eventid,
      eventattr: JSON.stringify(eventattr || {})
    })
}

const mineCoursewareStatResport = (
  eventid: STAT_EVENT,
  eventattr: MINE_COURSEWARES_EVENT_ATTR
) => {
  statReport(
    STAT_MODULE_ID.PREPARE_LESSON_MODULE_ID,
    STAT_PAGE.MINE_COURSEWARES_PAGE_ID,
    eventid,
    eventattr
  )
}

export { statReport, mineCoursewareStatResport }
