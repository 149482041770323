<template>
  <a-page-header
    title="编辑课件"
    :subTitle="fileName"
    style="background-color: white"
  >
    <template #extra>
      <a @click="handleGoBack">返回</a>
    </template>
  </a-page-header>
  <div style="background-color: white">
    <ppt-editor
      :resource="url"
      :pptName="fileName"
      ref="pptEditor"
      type="edit"
      resourceType="teacher"
      :autoHideMenu="false"
      height="80vh"
      @onSave="handleSave"
      is-teacher-mine="true"
    />
  </div>
</template>

<script>
import PptEditor from '@/components/ppt-editor/PptEditor.vue'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import { editTeacherCourseware, getTeacherCourseware } from './service'
import { eventCenter, event } from '@evideo/frontend-utils'
import config from '@/config'
import { message } from 'ant-design-vue'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'

export default {
  components: { PptEditor },
  beforeRouteLeave: (to, from, next) => {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })
    setTimeout(() => {
      next()
    }, 0)
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    const fileInfo = ref({})

    const fileName = ref('')

    const pptEditor = ref()

    const courseData = ref('')

    const url = ref()

    const handleGoBack = () => {
      pptEditor.value.checkAnShowSaveWarning(() =>
        router.push('../../mine-coursewares')
      )
    }

    const handleSave = async (data, callBack) => {
      try {
        fileInfo.value.url = data.url
        fileInfo.value.uuid = data.uuid
        fileInfo.value.relation_id = data.relation_id
        fileInfo.value.size = data.size
        fileInfo.value.md5 = data.md5
        const relate_plan_ids = sessionStorage.getItem('relate_plan_ids')
        const relate_speak_ids = sessionStorage.getItem('relate_speak_ids')
        const relatePlanIds = JSON.parse(
          relate_plan_ids === null ? '' : relate_plan_ids
        )
        const relateSpeakIds = JSON.parse(
          relate_speak_ids === null ? '' : relate_speak_ids
        )
        Object.assign(fileInfo.value, {
          thumbnail_images: data.previewImages || [],
          relate_courseware_ids: [...relatePlanIds, ...relateSpeakIds]
        })
        await editTeacherCourseware(id, fileInfo.value)
        mineCoursewareStatResport(STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_EDIT, {
          courseware_id: id,
          courseware_type: 'ppt',
          file_path: fileInfo.value.url
        })
        callBack.reply({ errorcode: 0, message: '保存成功' })
        message.success('保存成功')
      } catch (error) {
        console.log(error)
        callBack.reply({ errorcode: 1, message: '保存失败' })
      }
    }

    onMounted(async () => {
      const data = await getTeacherCourseware(id)
      url.value = data.url
      fileName.value = data.name
      fileInfo.value = data
      courseData.value = data.relate_coursewares.map(i => i.id)
      const relateCoursewares = data.relate_coursewares
      sessionStorage.setItem(
        'relate_plan_ids',
        JSON.stringify(
          relateCoursewares.filter(i => i.type === 'plan').map(i => i.id)
        )
      )
      sessionStorage.setItem(
        'relate_speak_ids',
        JSON.stringify(
          relateCoursewares.filter(i => i.type === 'speak').map(i => i.id)
        )
      )
      eventCenter.sub('PPT_GO_BACK', () => {
        handleGoBack()
      })
    })

    return {
      url,
      fileName,
      fileInfo,
      pptEditor,
      handleGoBack,
      handleSave
    }
  }
}
</script>

<style></style>
